import React, { useContext } from "react";
import { DarkModeContext } from "../App";
import cvIcon from '../icons/cv.svg'
import cvLightIcon from '../icons/cv-light.svg'
import githubIcon from '../icons/github.svg'
import githubLightIcon from '../icons/github-light.svg'
import linkedinIcon from '../icons/linkedin.svg'
import linkedinLightIcon from '../icons/linkedin-light.svg'
import mailIcon from '../icons/mail.svg'
import mailLightIcon from '../icons/mail-light.svg'
import todorokiGif from '../icons/todoroki.gif'
// import todoroki from '../icons/todoroki.jpg'
import './AboutMe.scss'


export default function AboutMe() {
    const { isDarkMode } = useContext(DarkModeContext);

    return <div>
        <div className="aboutMe-header">
            <div className="aboutMe-header-left">
                <h1 className="aboutMe-header-left-name">Syed Nabil Ashraf</h1>
                <div className="aboutMe-header-left-socials">
                    {/* <a className="aboutMe-header-left-socialsIconContainer" target='_blank'>
                        <img className="aboutMe-header-left-socialsIcon" src={isDarkMode ? cvIcon : cvLightIcon}></img>
                    </a> */}
                    <a className="aboutMe-header-left-socialsIconContainer" href="mailto:nabilashrafofficial@gmail.com" target='_blank'>
                        <img className="aboutMe-header-left-socialsIcon" src={isDarkMode ? mailIcon : mailLightIcon}></img>
                    </a>
                    <a className="aboutMe-header-left-socialsIconContainer" href="https://github.com/syednabilashraf" target='_blank'>
                        <img className="aboutMe-header-left-socialsIcon" src={isDarkMode ? githubIcon : githubLightIcon}></img>
                    </a>
                    <a className="aboutMe-header-left-socialsIconContainer" href="https://www.linkedin.com/in/syednabilashraf/" target='_blank'>
                        <img className="aboutMe-header-left-socialsIcon" src={isDarkMode ? linkedinIcon : linkedinLightIcon}></img>
                    </a>
                </div>
            </div>
            <div className="aboutMe-header-right">
                <img className="aboutMe-header-right-image" src={todorokiGif} alt='wakanda'></img>
            </div>
        </div>

        <div className={`aboutMe-description ${isDarkMode ? 'dark' : 'light'}`}>
            <p>
                Nabil is a full-time Software Engineer at <a href="https://www.optimizely.com/" target='_blank'>Optimizely</a> and a part-time Software Engineer at <a href="https://www.fulflld.com/">Fulflld</a>.
                His research interests lie primarily in Software Engineering and AI for constructing correct software. Specifically, he is interested in studying program analysis techniques and building automated tools to improve software quality and developer productivity.
            </p>

            {/* <p>
            His current research is on detection of different diseases in chest X-Rays using vision-transformers, CNNs and hybrid models with 
            <a href="https://scholar.google.com/citations?user=t4GrJR4AAAAJ&hl=en" target='_blank'> Prof. Golam Rabiul Alam.</a>
            </p>

            <p>Previously, he received his bachelor’s degree in Computer Science and Engineering at <a href="https://www.bracu.ac.bd/" target='_blank'>BRAC University</a>
                , where he worked on classifying Schizophrenic brain images with <a href="https://scholar.google.com/citations?user=t9cCPBAAAAAJ&hl=en" target='_blank'>
                    Prof. Amitabha Chakrabarty</a>. 
            </p> */}
 
            {/* <p>Check his Curriculum Vitae for more details.</p> */}
        </div>

    </div>
}